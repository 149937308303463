import {useCallback, useEffect, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

export function useRecaptcha() {
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const {executeRecaptcha} = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('register');
    setCaptchaToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return {captchaToken, handleReCaptchaVerify};
}
